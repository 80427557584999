import _get from 'lodash/get'
import { datoFpDebug, defaultBase64 } from '../../config/site'

export const baseImageSrc = (image) => {
  let src = `${image.url}?`

  let fpX = _get(image, 'focalPoint.x', '0.50')
  let fpY = _get(image, 'focalPoint.y', '0.50')

  return `${src}&fp-debug=${datoFpDebug}&crop=focalpoint&fit=crop&fp-x=${fpX}&fp-y=${fpY}`
}

export const backgroundStyle = (image) => {
  const style = {
    backgroundSize: `cover`,
    backgroundImage: defaultBase64,
  }

  if (image.sizes && image.sizes.base64) {
    style.backgroundImage = `url(${image.sizes.base64})`
  }

  return style
}
