import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import dayjs from 'dayjs'

import Thumbnail from './NewsThumbnail'

import ArrowRightSVG from '../../../assets/svg/arrow-right.svg'

const humanDate = (date) => dayjs(date).format('DD MMM YYYY')

const NewsArticleLink = ({ article, size }) => {
  return (
    <div className="c-newsItem">
      {size === 'md' && (
        <div className="c-newsItem__header">
          <Link to="/news">News</Link>
        </div>
      )}
      <Link to={article.relPath}>
        <Thumbnail
          image={article.thumbnailImage ?? article.heroImage}
          size={size}
        />
      </Link>
      <p className="c-newsItem__meta">
        <time dateTime={article.pubDateTime}>
          {humanDate(article.pubDateTime)}
        </time>
        {` • `}
        <Link to={article.categoryLink} className="c-newsItem__meta__link">
          {article.category}
        </Link>
      </p>
      <h3 className="c-newsItem__title">
        <Link to={article.relPath}>{article.title}</Link>
      </h3>

      <Link
        to={article.relPath}
        className="c-button c-button--secondary"
        title={'Read ' + article.title}
      >
        <ArrowRightSVG className="c-icon mr-2" />
        Read
      </Link>
    </div>
  )
}

NewsArticleLink.defaultProps = {
  size: 'md',
}

NewsArticleLink.propTypes = {
  article: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['lg', 'md', 'sm']),
}

export default NewsArticleLink
