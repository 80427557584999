module.exports = {
  siteUrl: 'https://erudus.com',
  sitemapPath: '/sitemap.xml',

  title: 'Erudus, united around food data',
  description:
    'Erudus is the source of accurate allergy, nutritional and technical food product data | Tools and solutions for Food Manufacturers, Wholesalers and Caterers',

  backgroundColor: '#f4f4f4', // Used for setting manifest background color.
  themeColor: '#072f3a', // Used for setting manifest theme colors.
  progressColor: 'ffc010', // Used for setting progress bar color.

  defaultBase64: `url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAUABQDAREAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAr/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAcI/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8AkfWBlcAAAAAAAB//2Q==')`, // Used for default image blur-up

  typekit: 'rmq3gox',

  datoFpDebug: false,

  sitemap: {
    excludeList: [
      `/search`,
      `/welcome-*`,
      `/know-your-food`,
      `/fadne`,
      `/erp`,
      `/api-policy`,
      // News Pages
      `/news/page/*`,
      `/news/erudus/page/*`,
      `/news/industry/page/*`,
      `/news/data-pool/page/*`,
      `/news/podcast/page/*`,
      `/news/release-notes/page/*`,
      `/news/you-should-know/page/*`,
      // Editorial Pages
      `/editorial/page/*`,
      `/editorial/the-food-agenda/page/*`,
      `/editorial/recipe-book/page/*`,
      `/editorial/insider-diaries/page/*`,
      `/editorial/erudus-community/page/*`,
      `/editorial/the-takeaway/page/*`,
      `/events/page/*`,
      // Sign up Forms
      // **Not Manufacturers
      `/wholesalers/sign-up/*`,
      `/caterers/sign-up/*`,
      `/retailers/sign-up/*`,
      `/erp/contact`,
      // Demo Forms
      `/manufacturers/demo`,
      `/wholesalers/demo`,
      // Success Pages
      `/contact-us/success`,
      `/sign-up/success`,
      `/demo/success`,
      `/image-capture/success`,
      `/newsletter-success`,
      `/pim/success`,
      `/erp/success`,
    ],
  },
}
