module.exports = {
  orgJSONLD: {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'Erudus',
    legalName: 'Erudus Limited',
    url: 'https://erudus.com',
    logo: 'https://erudus.com/images/erudus-logo-hd.png',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Panther House, Asama Court, Newcastle Business Park',
      addressLocality: 'Newcastle Upon Tyne',
      addressRegion: 'Tyne and Wear',
      postalCode: 'NE4 7YD',
      addressCountry: 'United Kingdom',
    },
    contactPoint: [
      {
        '@type': 'ContactPoint',
        contactType: 'Customer Support',
        telephone: '(+44) 0333 121 89 99',
        email: 'support@erudus.com',
      },
      {
        '@type': 'ContactPoint',
        contactType: 'Sales Enquires',
        telephone: '(+44) 0330 088 60 33',
        email: 'sales@erudus.com',
      },
    ],
    sameAs: [
      'https://twitter.com/ErudusUK',
      'https://twitter.com/ErudusAllergy',
      'https://github.com/Erudus',
      'https://vimeo.com/erudusone',
      'https://www.linkedin.com/company/erudusuk/',
      'https://www.youtube.com/channel/UC4SKF5HvgItjeXYwjjhR8Tw',
      'https://www.facebook.com/ErudusUK/',
      'https://www.instagram.com/erudusuk/',
      'https://www.pinterest.co.uk/erudusuk/',
      'https://one.erudus.com',
      'https://fic.erudus.com',
      'https://calc.erudus.com',
      'https://knowledgebase.erudus.com/',
    ],
  },
  breadcrumbJSONLD: {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [],
  },
}
