import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import { orgJSONLD, breadcrumbJSONLD } from '../../config/schema'

function Schema({ breadcrumbs, webPage, recipe }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )

  let JSONLD = [orgJSONLD]

  if (breadcrumbs.length > 0) {
    let completeItemList = [
      {
        '@type': 'ListItem',
        position: 1,
        name: `Home`,
        item: site.siteMetadata.siteUrl,
      },
      ...breadcrumbs.map(({ name, uri }, i) => {
        return {
          '@type': 'ListItem',
          position: i + 2,
          name,
          item: `${site.siteMetadata.siteUrl}${uri}`,
        }
      }),
    ]

    breadcrumbJSONLD.itemListElement = completeItemList

    JSONLD = [...JSONLD, ...[breadcrumbJSONLD]]
  }

  if (webPage) {
    JSONLD = [...JSONLD, ...[webPage]]
  }

  if (recipe) {
    JSONLD = [...JSONLD, ...[recipe]]
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(JSONLD)}</script>
    </Helmet>
  )
}

Schema.defaultProps = {
  breadcrumbs: [],
  webPage: null,
  recipe: null,
}

Schema.propTypes = {
  breadcrumbs: PropTypes.array,
}

export default Schema
